import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "./styles/pages/_landing.scss";

// react toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Marquee
import Marquee from "react-fast-marquee";

import whitelist from "./whitelist/whitelist.json";

// Animation On Scroll
import AOS from "aos";
import "aos/dist/aos.css";

// ABI
import ABI from "./ABI.json";

const contractAddress = "0x814Bbbb90B090e274901ff6de7932534217AaAFB";

// Styles and CSS

function App() {
	const [currentAccount, setCurrentAccount] = useState("");
	const [totalMinted, setTotalMinted] = useState(0);
	const [value, setValue] = useState(1);
	const [minting, setMinting] = useState(false);

	useEffect(() => {
		AOS.init();
	}, []);

	useEffect(() => {
		checkIfWalletIsConnected();
		if (window.ethereum) {
			getCount();

			window.ethereum.on("accountsChanged", checkIfWalletIsConnected);
			window.ethereum.on("disconnect", checkIfWalletIsConnected);
		}
	}, []);

	const checkIfWalletIsConnected = async () => {
		const { ethereum } = window;

		if (!ethereum) {
			toast("Make sure you have metamask!");
			return;
		}

		const accounts = await ethereum.request({ method: "eth_accounts" });

		if (accounts.length !== 0) {
			const account = accounts[0];

			setCurrentAccount(account);
		} else {
			setCurrentAccount("");
			// toast("No authorized account found");
		}
	};

	// Get Supply
	const getCount = async () => {
		const provider = new ethers.getDefaultProvider("homestead");
		const contract = new ethers.Contract(contractAddress, ABI, provider);

		const count = await contract.totalSupply();
		setTotalMinted(parseInt(count));
	};

	// Connect Wallet
	const connectWallet = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
		await provider.send("eth_requestAccounts", []);
		const signer = provider.getSigner();
		await signer.getAddress();
	};

	// Mint NFT
	const mintToken = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();
		const connectedContract = new ethers.Contract(contractAddress, ABI, signer);
		const cost = await connectedContract.mintRate();
		try {
			let result;
			result = await connectedContract.mint(value, {
				value: cost.mul(value),
			});
			setMinting(true);
			await result.wait();
			setMinting(false);
			toast.success("Mint Successful.", { position: "bottom-right" });
			getCount();
		} catch (err) {
			console.log(err);
			toast.error("Mint unsuccessful", { position: "bottom-right" });
		}
	};

	// Handle NFTs Count
	const handleCount = (type = "inc") => {
		if (type === "inc") {
			if (value < 4) {
				setValue(value + 1);
			}
		} else {
			if (value > 1) {
				setValue(value - 1);
			}
		}
	};

	return (
		<main
			className="main"
			style={{ minHeight: "100vh", position: "relative", overflow: "hidden" }}
		>
			<div className="video-wrapper">
				<img src="/assets/background.jpeg" alt="background" id="bg-video" />
				{/* <video
					src="/assets/background.mp4"
					autoPlay
					loop
					muted
					id="bg-video"
					data-aos="zoom-in"
				></video> */}
			</div>
			<img src="/assets/card.gif" alt="" className="img-1" />
			<img src="/assets/card.gif" alt="" className="img-2" />
			<Marquee
				speed={20}
				direction="left"
				className="marquee1"
				gradient={false}
				pauseOnHover={true}
			>
				<h1>
					{" "}
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse{" "}
				</h1>
			</Marquee>
			<ToastContainer />

			<div className="top-bar" data-aos="fade-up">
				<img
					src="https://louiseandfred.io/wp-content/uploads/2022/03/white_logo-1-150x150.png"
					alt="logo"
				/>
			</div>

			<section id="mint">
				<div className="container">
					<h4 style={{ fontSize: 16 }}>WHITELIST MINT IS NOW ONLINE</h4>
					<h1 className="heading" data-aos="fade-up">
						MINT YOUR EXCLUSIVE L&F MEMBER CARD NOW <br /> ONLY 5.000 NFTs
					</h1>
					<p className="eth-text" data-aos="fade-up">
						THE FIRST 500 (BLACK CARD) IS SUPER RARE <br /> THE NEXT 501 - 1200
						(GOLD CARD) IS RARE <br /> HURRY UP!! <br />
						<br /> WHITELIST PRICE ONLY 0.05 ETH + GAS FEE
					</p>
					<p className="eth-text" data-aos="fade-up">
						MAX 4 NFT PER TRANSACTION/WALLET
						<br />
						<br />
						Our recommendation, buy at least 2. hold one, sell one....
						IMPORTANT: BEFORE YOU MINT, CHECK THE CORRECTNESS OF THE DOMAIN:
						LOUISEANDFRED-MINT:IO
					</p>
					<p className="sub-text" data-aos="fade-up">
						MINTED: <span className="focus">{totalMinted} / 5000</span>
					</p>

					<div className="counter">
						<div className="col">
							<span onClick={() => handleCount("dec")} className="c-btn btn-1">
								-
							</span>
							<div className="count">{value}</div>
							<span onClick={() => handleCount("inc")} className="c-btn btn-2">
								+
							</span>
						</div>{" "}
						<div className="col">
							{[1, 2, 4].map((val) => (
								<div key={val} onClick={() => setValue(val)} className="box">
									{val}
								</div>
							))}
						</div>
					</div>
					<p style={{ marginBottom: 10 }}>Special deal buy 4 get 1 free</p>
					{currentAccount ? (
						<button className="connect-btn" onClick={mintToken}>
							<div>{minting ? "Minting..." : "Mint Now "}</div>
						</button>
					) : (
						<a
							className="connect-btn"
							href={
								window.ethereum
									? "#"
									: "https://metamask.app.link/dapp/louiseandfred-mint.io"
							}
							onClick={connectWallet}
							data-aos="zoom-in"
						>
							<div>{currentAccount ? "Connected" : "Connect Wallet"}</div>
						</a>
					)}
					<p className="bottom-text" data-aos="fade-up">
						WE ARE HAPPY TO START WITH YOU FOR THIS AMAZING PROJECT <br />
						LOUISE&FRED.
					</p>
				</div>
			</section>

			<Marquee
				speed={20}
				direction="right"
				className="marquee2"
				gradient={false}
				pauseOnHover={true}
			>
				<h1>
					{" "}
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse
					louis&fred Metaverse louis&fred Metaverse louis&fred Metaverse{" "}
				</h1>
			</Marquee>
		</main>
	);
}

export default App;
